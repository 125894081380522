.contract-btn {
    font-size: 16px;
    background: rgb(6, 199, 85);
    background: -moz-linear-gradient(90deg, rgba(6, 199, 85, 1) 0%, rgba(74, 212, 131, 1) 35%, rgba(188, 207, 29, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(6, 199, 85, 1) 0%, rgba(74, 212, 131, 1) 35%, rgba(188, 207, 29, 1) 100%);
    background: linear-gradient(90deg, rgba(6, 199, 85, 1) 0%, rgba(74, 212, 131, 1) 35%, rgba(188, 207, 29, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#06c755", endColorstr="#bccf1d", GradientType=1);
    border-radius: 5px;
    padding: 15px 54px;
    margin-left: 10px;
  
  }
  
  .contract-btn a {
    font-weight: 600;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #2c323f;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 7px;
    margin-left: 10px;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: #2c323f;
    color: white !important;
    border-top: #f1f1f12b dashed 2px !important;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1 !important;
    color: black !important;
  }
  
  .dropdown-menu .dropdown-item {
    background: none;
  }
  
  .cartoon-right {
    left: 67rem !important;
  }
  .cartoon-left {
    left: -55px !important;
  }
  .show .navbar_nav_modify .dropdown-menu{
    background-color: #fff !important;
    color: black !important;
  }
  .show .navbar_nav_modify .dropdown-menu .dropdown-item{
    color: black !important;
  }